.terms-container {
    max-width: 800px; /* Ограничиваем максимальную ширину */
    margin: 0 auto; /* Центруем контейнер */
    padding: 20px; /* Добавляем отступы */
    font-size: 16px; /* Устанавливаем размер шрифта */
    line-height: 1.5; /* Увеличиваем межстрочный интервал */
}

h1, h2 {
    margin-top: 20px; /* Отступы сверху для заголовков */
    margin-bottom: 10px; /* Отступы снизу для заголовков */
}

.scroll-to-top {
    position: fixed;
    bottom: 80px;
    right: 16px;
    width: 50px;
    height: 50px;
    background-color: #FF9800;
    color: white;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.scroll-to-top::before {
    content: '';
    display: block;
    width: 10px; /* Ширина стрелки */
    height: 10px; /* Высота стрелки */
    border-left: 2px solid white; /* Линия слева */
    border-bottom: 2px solid white; /* Линия снизу */
    transform: rotate(135deg); /* Поворот на 45 градусов */
}

.scroll-to-top:hover {
    background-color: #FF5722;
}
