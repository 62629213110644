.search-bar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    padding: 20px;
    background-color: #f0f4f8;
    border-radius: 50px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 1110px;
    gap: 15px;
    margin-bottom: 10px !important;
}
  
.search-bar-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 20px;
    
}
  
.search-input {
    background-color: #ffffff !important;
    border-radius: 50px !important;
    width: 300px; /* Регулируем ширину поля поиска */
    height: 50px; /* Регулируем высоту поля поиска */
    display: flex;
    align-items: center; /* Центрируем содержимое по вертикали */
}
  
.search-input fieldset {
    border: 2px solid orange !important; /* Постоянно оранжевая обводка */
    border-radius: 50px !important;
    transition: none !important; /* Убираем анимацию */
}

/* Настройки иконок внутри поля поиска */
.search-input .MuiInputAdornment-root {
    margin-left: 10px; /* Отступ для иконки */
    margin-right: 10px; /* Отступ для иконки */
}

.search-input .MuiIconButton-root {
    padding: 5px; /* Регулируем размер кнопки */
    margin-right: 10px; /* Отступ справа */
}
  
.date-picker-container {
    background-color: #ffffff !important;
    border-radius: 50px !important;
    width: 300px !important; /* Регулируем ширину поля выбора даты */
    height: 50px; /* Регулируем высоту поля выбора даты */
    display: flex;
    align-items: center; /* Центрируем содержимое по вертикали */
}
  
.date-picker-container fieldset {
    border: 2px solid orange !important; /* Постоянно оранжевая обводка */
    border-radius: 50px !important;
    transition: none !important; /* Убираем анимацию */
}

/* Обновлённые настройки кнопки очистки в поле выбора даты */
.search-bar-container .date-picker-container .react-datepicker__close-icon {
    position: absolute !important;
    right: 10px !important; /* Расположение крестика справа */
    top: 50% !important; /* Вертикальное выравнивание в центре поля */
    transform: translateY(20%) !important; /* Сдвиг ниже для выравнивания */
    z-index: 10 !important; /* Убедитесь, что элемент видим */
    width: 20px !important; /* Размер кнопки */
    height: 20px !important; /* Размер кнопки */
    cursor: pointer !important;
}

/* Настройки иконок внутри поля выбора даты */
.date-picker-container .MuiInputAdornment-root {
    margin-left: 10px; /* Отступ для иконки */
    margin-right: 10px; /* Отступ для иконки */
}
  
.people-filter-input {
    background-color: #ffffff !important;
    border-radius: 50px !important;
    width: 650px !important; /* Регулируем ширину поля фильтра по людям */
    height: 50px; /* Регулируем высоту поля фильтра по людям */
    display: flex;
    align-items: center; /* Центрируем содержимое по вертикали */
}
  
.people-filter-input fieldset {
    border: 2px solid orange !important; /* Постоянно оранжевая обводка */
    border-radius: 50px !important;
    transition: none !important; /* Убираем анимацию */
}

/* Настройки иконок внутри фильтра по людям */
.people-filter-input .MuiInputAdornment-root {
    margin-left: 10px; /* Отступ для иконки */
    margin-right: 10px; /* Отступ для иконки */
}

/* Сохранение ховера и фокуса для пользователей */
.search-input:hover fieldset,
.date-picker-container:hover fieldset,
.people-filter-input:hover fieldset {
    border-color: orange !important; /* Дополнительное правило для совместимости */
}

.search-input .MuiOutlinedInput-root.Mui-focused fieldset,
.date-picker-container .MuiOutlinedInput-root.Mui-focused fieldset,
.people-filter-input .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: orange !important; /* Сохранение оранжевой обводки на фокусе */
}

/* Версия только для мобильных устройств */
@media (max-width: 600px) {
    .search-bar-container {
        flex-direction: column;
        gap: 15px;
        align-items: center;
    }

    .search-input,
    .date-picker-container,
    .people-filter-input {
        width: 100% !important; /* Поля одной ширины */
    }

    .search-bar-row {
        flex-direction: column; /* Ставим поля в колонку */
        gap: 10px;
        margin: 0
    }
}