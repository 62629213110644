.card-container {
    width: 100%;
    height: 100%;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: inherit;
    border-radius: 10px !important;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-container:hover {
    transform: translateY(-5px) scale(1.03);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

/* Серый фильтр для неактивных карточек */
.inactive-card {
    filter: grayscale(100%); /* Делаем карточки серыми */
    opacity: 0.8; /* Легкая прозрачность */
    transition: opacity 0.3s ease, filter 0.3s ease;
}

.inactive-card:hover {
    transform: translateY(0); /* Убираем анимацию смещения при наведении */
    box-shadow: none; /* Убираем тень */
    cursor: not-allowed; /* Указываем, что элемент не активен */
}

.card-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.card-content {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.card-title {
    margin: 0;
}

.card-price {
    color: orange;
    font-weight: bold;
    margin: 0;
    line-height: 1;
}

.card-description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-top: 4px;
}
