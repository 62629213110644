/* Центровка основного контейнера */
.tour-container {
    max-width: 900px; /* Немного увеличили ширину */
    margin: auto;
    padding: 20px;
    background-color: #f9f9f9; /* Легкий фон для общей области */
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Заголовок тура */
.tour-title {
    text-align: center;
    font-size: 2.2rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    text-transform: uppercase; /* Преобразование в верхний регистр */
    letter-spacing: 1px;
    border-top: 4px solid #FF9800;
    border-bottom: 4px solid #FF9800; /* Подчеркивание заголовка */
    padding-top: 10px;
    padding-bottom: 10px;
}

/* Блок дат проведения */
.tour-dates {
    padding: 16px;
    border-radius: 8px;
    margin-top: 20px;
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.tour-dates .compact-date-item {
    text-align: center;
    padding: 8px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    margin-bottom: 10px;
    background-color: #fafafa;
    transition: transform 0.2s ease;
}

.tour-dates .compact-date-item:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tour-dates .date-range {
    display: block;
    font-weight: bold;
    color: #555;
}

.tour-dates .free-seats {
    color: #FF5722;
    font-weight: bold;
    margin-top: 4px;
}

/* Информационные блоки */
.tour-info-box {
    
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    margin-top: 20px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    
}
.tour-additional-info {
    
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    margin-top: 20px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    
}

.tour-info-box h6 {
    color: #FF9800;
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 1.1rem;
}

.tour-info-box {
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    margin-left: 0;
    padding-left: 16px; /* Делаем аккуратный внутренний отступ */
}

.tour-additional-info {
    border-left: 4px solid #FF9800;
    padding-left: 12px;
}

/* Условие скрытия блока */
.tour-info-box.hidden {
    display: none;
}

/* Кнопка бронирования */
.booking-button {
    position: fixed;
    bottom: 16px;
    right: 16px;
    z-index: 1000;
    padding: 12px 24px;
    background-color: #FF9800;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}

.booking-button:hover {
    background-color: #FF5722;
    transform: translateY(-3px);
}

/* Кнопка "Прокрутить вверх" */
.scroll-to-top-button {
    position: fixed;
    bottom: 80px;
    right: 16px;
    z-index: 1000;
    width: 50px;
    height: 50px;
    background-color: #FF9800;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, transform 0.2s ease;
}

.scroll-to-top-button:hover {
    background-color: #FF5722;
    transform: translateY(-3px);
}

/* Дополнительные стили для разделов */
.tour-section {
    margin-bottom: 30px;
    padding: 16px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.tour-section h6 {
    color: #FF9800;
    font-weight: bold;
    margin-bottom: 10px;
}

.tour-section p {
    color: #555;
    line-height: 1.6;
}
