/* Галерея для десктопа */
.desktop-gallery {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 8px;
  border-radius: 16px;
  overflow: hidden;
  max-height: 500px;
  margin-bottom: 10px;
}

.gallery-main {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
}

.gallery-main img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.gallery-thumbnails {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 8px;
}

.thumbnail {
  width: 100%;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
}

.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.thumbnail:hover img {
  transform: scale(1.1);
}

/* Галерея для мобильной версии */
.mobile-gallery {
  display: none;
  position: relative;
  max-height: 400px;
  margin-bottom: 20px;
}

.slider-image img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 12px;
}

/* Индикатор текущего изображения */
.image-indicator {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 14px;
}

/* Условное отображение для мобильной версии */
@media (max-width: 768px) {
  .desktop-gallery {
    display: none;
  }

  .mobile-gallery {
    display: block;
  }
}
